import React, {useContext} from 'react'
import {IoLogoReact} from "react-icons/io5"
import {IoLogoJavascript} from "react-icons/io"
import {IoLogoSass} from "react-icons/io5"
import {FaCss3} from "react-icons/fa"
import {IoLogoFirebase} from 'react-icons/io5'
import {MdOutlineSportsVolleyball} from 'react-icons/md'
import {RiGameFill} from 'react-icons/ri'
import {ImBook} from 'react-icons/im'
import {SlScreenDesktop} from 'react-icons/sl'
import {FaTwitch} from 'react-icons/fa'
import "./competences.css"
import data from "../../assets/data.js"
import { Context } from '../../context/langContext'





const Competences = () => {

  const {lang} = useContext(Context)


  return (
<div className='comp'>
    <div className='intro'>
    <hr className='hr'></hr>
    <h1>{data[lang].NavBar.Competences}</h1>
</div>


    <div className='Competences'>
        <div className='Tools'>
          <h1>Tools</h1>
          <div className='logo'> 
           <div className='logo-texte'><IoLogoReact size="6em" color='#61DBFB'/> <p>React</p></div>
           <div className='logo-texte'><IoLogoJavascript size="6em" color='#F0DB4F' /> <p>Javascript</p></div>
           <div className='logo-texte'> <FaCss3 size="6em" color='#264de4'/> <p>CSS</p></div>
           <div className='logo-texte'> <IoLogoSass size="6em" color='#c69' /> <p>Sass</p></div>
           <div className='logo-texte'> <IoLogoFirebase size="6em" color='#FFA611'/> <p>Firebase</p></div>
            </div>   
        </div>
    </div>

    <div className='Divers'>
        <div className='Hobbies'>
          <h1>Hobbies</h1>
          <div className='logo'> 
           <div className='logo-texte'><MdOutlineSportsVolleyball size="6em" color='black'/> <p>Sport</p></div>
           <div className='logo-texte'><RiGameFill size="6em" color='#F0DB4F' /> <p>Games</p></div>
           <div className='logo-texte'> <ImBook size="6em" color='#ffd08f'/> <p>Manga</p></div>
           <div className='logo-texte'> <SlScreenDesktop size="6em" color='gray' /> <p>Series</p></div>
           <div className='logo-texte'> <FaTwitch size="6em" color='#9146FF'/> <p>Twitch</p></div>
            </div>   
        </div>
    </div>
    </div>
  )
}

export default Competences