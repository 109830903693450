import React, { useEffect, useRef, useContext } from 'react';
import "./footer.css"
import data from "../../assets/data.js"
import { Context } from '../../context/langContext'
import Logo from "../../images/icon.png"
import {BsLinkedin} from "react-icons/bs"
import {BsGithub} from "react-icons/bs"
 
function Footer() {
  
  const footerRef = useRef(null);
  const {lang} = useContext(Context)


  useEffect(() => {
    const handleResize = () => {
      const footerHeight = footerRef.current.offsetHeight;
      document.body.style.paddingBottom = `${footerHeight}px`;
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  
  }, []);

  const year = new Date().getFullYear();
  return (
     
      <footer ref={footerRef} className="dn-footer">
        <div className="dn-container">
          <img className="dn-image" src={Logo} alt="logo" />
          <p className="dn-copyright">&copy; {year} Dieu Nathan. {data[lang].Footer}</p>
            <ul className="dn-contact-list">
                <li className="dn-contact-item">
                    <a href="mailto:dieunathan@example.com" className="dn-contact-link">✉️ contact.nathandieu.com</a>
                </li>
                <li className="dn-contact-item">
                    <a href="https://www.linkedin.com/in/dieunathan" target="_blank" className="dn-contact-link"><BsLinkedin color='#0e76a8' /> LinkedIn</a>
                </li>
                <li className="dn-contact-item">
                    <a href="https://github.com/Nathantenpouin" target="_blank" className="dn-contact-link"><BsGithub color='#171515' /> GitHub</a>
                </li>
            </ul>
        </div>
    </footer>
   
  );
}

export default Footer;
