import React, { useState, useContext } from "react";
import "./experience.css";
import { MdWork } from "react-icons/md";
import { BsFillClockFill } from "react-icons/bs";
import data from "../../assets/data.js"
import { Context } from '../../context/langContext'


const Experience = () => {
  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);

  const toggleState = () => {
    setToggle(!toggle);
  };

  const toggleState2 = () => {
    setToggle2(!toggle2);
  };

  const toggleState3 = () => {
    setToggle3(!toggle3);
  };

  const {lang} = useContext(Context)

  return (
    <div className="experience">
      <div className="intro">
        <hr className="hr" />
        <h1>{data[lang].NavBar.Experience}</h1>
      </div>

      <hr className="hr-logo" />

      <div className="container-experience">
        <div className="container-info">
          <div onClick={toggleState} className="info">
           <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <a href="https://inovelec-groupe.com" target="_blank">
              INOVELEC
            </a> 🖱️</div>
            <h4>{data[lang].Experience.Container1.Titre}</h4>
            <p>
              <BsFillClockFill /> 2019-2020
            </p>
          </div>

          <div className={toggle ? "logo-background2" : "logo-background"}>
            <MdWork size="2em" color="#d82c2e" />
          </div>

          <div className={toggle ? "accord-toggle animated" : "accord-toggle"}>
            <p aria-hidden={toggle ? "true" : "false"}>
              - {data[lang].Experience.Container1.Premier}
            </p>
            <p aria-hidden={toggle ? "true" : "false"}>
              {" "}
              - {data[lang].Experience.Container1.Second}
            </p>
            <p aria-hidden={toggle ? "true" : "false"}>
              - {data[lang].Experience.Container1.Troisieme}
            </p>
          </div>
        </div>

        <div className="container-info2">
          <div onClick={toggleState2} className="info">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <a
              href="https://www.perigord-commerce.com/commercants/autres-commerces/autres-commerces-informatique/station-service-informatique"
              target="_blank"
            >
              Station Service Informatique
            </a> 🖱️</div>
            <h4>{data[lang].Experience.Container2.Titre}</h4>
            <p>
              <BsFillClockFill /> 2018-2019
            </p>
          </div>

          <div className={toggle2 ? "logo-background4" : "logo-background3"}>
            <MdWork size="2em" color="#d82c2e" />
          </div>

          <div className={toggle2 ? "accord-toggle animated" : "accord-toggle"}>
            <p aria-hidden={toggle2 ? "true" : "false"}>
              - {data[lang].Experience.Container2.Premier}
            </p>
            <p aria-hidden={toggle2 ? "true" : "false"}>
              - {data[lang].Experience.Container2.Second}
            </p>
            <p aria-hidden={toggle2 ? "true" : "false"}>
              - {data[lang].Experience.Container2.Troisieme}
            </p>
          </div>
        </div>

        <div className="container-info3">
          <div onClick={toggleState3} className="info">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <a
              href="https://www.afpa.fr/formation-qualifiante/technicien-superieur-systemes-et-reseaux"
              target="_blank"
            >
              T2SR
            </a> 🖱️</div>
            <h4>{data[lang].Experience.Container3.Titre}</h4>
            <p>
              <BsFillClockFill /> 2018-2019
            </p>
          </div>

          <div className={toggle3 ? "logo-background6" : "logo-background5"}>
            <MdWork size="2em" color="#d82c2e" />
          </div>

          <div className={toggle3 ? "accord-toggle animated" : "accord-toggle"}>
            <p aria-hidden={toggle3 ? "true" : "false"}>
              - {data[lang].Experience.Container3.Premier}
            </p>
            <p aria-hidden={toggle3 ? "true" : "false"}>
              - {data[lang].Experience.Container3.Second}
            </p>
            <p aria-hidden={toggle3 ? "true" : "false"}>
              - {data[lang].Experience.Container3.Troisieme}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
