const traductions = {
  FR: {
    NavBar: {
      Moi: "Moi",
      Competences: "Compétences",
      Experience: "Expérience",
      Education: "Éducation",
      Project: "Project",
      Portfolio: "Portfolio",
      Contact: "Contact",
    },
    Moi: {
      Presentation: "Bonjour ! Je suis Nathan Dieu",
      Joke: "Et fan de React",
      DV: "Développeur Front-End Junior ",
      Telecharger: "Télécharger CV",
      Propos: "À propos de moi :",
      Description: "Je suis un développeur web junior passionné par la création d'expériences utilisateur exceptionnelles et la résolution de problèmes complexes. J'ai une solide compréhension des technologies front-end, notamment HTML, CSS, JavaScript et React et je suis toujours désireux d'apprendre de nouvelles compétences pour m'améliorer en tant que développeur."
    },
    Experience: {
      Container1: {
        Societe: "INOVELEC",
        Titre: "Technicien Réseau",
        Premier: "Maintenir et exploiter un domaine Active Director.",
        Second: "Mettre en place les VLAN avec Pfsense.",
        Troisieme:
          "Configurer les services de déploiement et de terminaux clients légers.",
      },
      Container2: {
        Societe: "Station Service Informatique",
        Titre: "Technicien Informatique",
        Premier: "Assemblage PC/PC Portable.",
        Second: "Nettoyage de Virus.",
        Troisieme: "Récupération de données dans un Disque Dur.",
      },
      Container3: {
        Societe: "T2SR",
        Titre: "Technicien Supérieur en Système et Réseau",
        Premier: "Assistance aux utilisateurs (Help Desk)",
        Second: "Installation de VOIP et configuration sur Asterisk.",
        Troisieme: "Paramétrage de port sur Switch CISCO sur Putty.",
      },
    },
    Diplome: [
      {
        id: 1,
        nom: "EREA de Berck-sur-Mer",
        titre: "BAC S",
        annee: "2012-2015",
        description1: "Spécialité Physique Chimie",
        description2: "Spécialité Mathématiques",
      },
      {
        id: 2,
        nom: "Lycée Jean-Baptiste de Baudre Agen",
        titre: "Technicien Supérieur en Système et Réseau",
        annee: "2018-2019",
        description1: "Assister les utilisateurs en centre de services",
        description2:
          "Maintenir, exploiter et sécuriser une infrastructure centralisée",
      },
      {
        id: 3,
        nom: "Udemy.com",
        titre: "Formation Développeur Web",
        annee: "2022",
        description1: "HTML, CSS, Javascript",
        description2: "Responsive Design",
      },
      {
        id: 4,
        nom: "Udemy.com",
        titre: "React de A à Z",
        annee: "2022-2023",
        description1: "Hooks, Redux",
        description2: "Plusieurs Projects React",
      },
    ],
    Error: {
      toast:
        "Vous allez être redirigé vers la page d'accueil dans 10 secondes...",
      texte: "On dirait que cette page s'est perdue dans l'espace-temps... ",
    },
    Footer: "Tous droits réserves.",

    Project: {
      Calcul: "Calculateur",
      Span: "d'IMC",
      Taille: "Taille (cm)",
      Poids: "Poids (kg)",
      Calculer: "Calculer",
      Remplir: "Remplissez correctement les inputs.",
      Maigreur: "Maigreur",
      BS: "Bonne santé",
      Surpoids: "Surpoids",
      OM: "Obésité modérée",
      OS: "Obésité sévère",
      OMM: "Obésité morbide",
      Tool: "Indice pondéral calculé en divisant le poids d'une personne par le carré de sa taille."
    },
    Portfolio: {
      Twice: "Présentation Twice",
      Reve: "Rêve dans le monde",
    },
    Contact: {
      Nom: 'Nom/Prénom',
      Email: "Adresse Mail",
      Tel: "Numéro de Téléphone (optionnel)",
      Envoyer: "Envoyer", 
      Toast: "Votre message vient de prendre son envol vers sa destination, mission accomplie ! 🚀"
    }
  },
  EN: {
    NavBar: {
      Moi: "Me",
      Competences: "Skills",
      Experience: "Experience",
      Education: "Education",
      Project: "Project",
      Portfolio: "Portfolio",
      Contact: "Contact",
    },
    Moi: {
      Presentation: "Hello ! I'm Nathan Dieu",
      Joke: "And a React fan",
      DV: "Junior Front End Developer",
      Telecharger: "Download CV",
      Propos: "About me :",
      Description: "I am a junior web developer with a passion for creating exceptional user experiences and solving complex problems. I have a strong understanding of front-end technologies including HTML, CSS, JavaScript and React and am always eager to learn new skills to improve myself as a developer."
    },
    Experience: {
      Container1: {
        Societe: "INOVELEC",
        Titre: "Network Technician",
        Premier: "Maintaining an Active Director domain.",
        Second: "Set up VLANs with Pfsense.",
        Troisieme:
          "Configure the deployment services and thin client terminals.",
      },
      Container2: {
        Societe: "Station Service Informatique",
        Titre: "Computer Technician",
        Premier: "PC/Laptop assembly.",
        Second: "Virus Cleanup.",
        Troisieme: "Hard Disk Data Recovery.",
      },
      Container3: {
        Societe: "T2SR",
        Titre: "Technician in System and Network",
        Premier: "User Support (Help Desk)",
        Second: "Installation of VOIP and configuration on Asterisk.",
        Troisieme: "Port setting on CISCO Switch on Putty.",
      },
    },
    Diplome: [
      {
        id: 1,
        nom: "EREA de Berck-sur-Mer",
        titre: "French HS diploma, Science major",
        annee: "2012-2015",
        description1: "Physical Chemistry specialty",
        description2: "Mathematics specialty",
      },
      {
        id: 2,
        nom: "Lycée Jean-Baptiste de Baudre Agen",
        titre: "Technician in System and Network",
        annee: "2018-2019",
        description1: "Assist users in the service center",
        description2:
          "Maintain, operate and secure a centralized infrastructure",
      },
      {
        id: 3,
        nom: "Udemy.com",
        titre: "Web Developer Training",
        annee: "2022",
        description1: "HTML, CSS, Javascript",
        description2: "Responsive Design",
      },
      {
        id: 4,
        nom: "Udemy.com",
        titre: "React from A to Z",
        annee: "2022-2023",
        description1: "Hooks, Redux",
        description2: "Multiple React Projects",
      },
    ],
    Error: {
      toast: "You will be redirected to the home page in 10 seconds...",
      texte: "Looks like this page got lost in space-time...",
    },
    Footer: "All rights reserved.",
    Project: {
      Calcul: "Calculator",
      Span: "from BMI",
      Taille: "Height (cm)",
      Poids: "Weight (kg)",
      Calculer: "Calculate",
      Remplir: "Fill in the inputs correctly.",
      Maigreur: "Thinness",
      BS: "Good health",
      Surpoids: "Overweight",
      OM: "Moderate obesity",
      OS: "Severe obesity",
      OMM: "Morbid obesity",
      Tool: "Weight index calculated by dividing a person's weight by the square of their height."
    },
    Portfolio: {
      Twice: "Twice Presentation",
      Reve: "Dream in the world",
    },
    Contact: {
      Nom: 'Last name/First name',
      Email: "Email Address",
      Tel: "Telephone number (optional)",
      Envoyer: "Submit", 
      Toast: "Your message has just taken off towards its destination, mission accomplished! 🚀"
    }
  },
};

export default traductions;
