import React, { useState, useContext } from "react";
import { useForm } from "@formspree/react";
import { toast, ToastContainer } from 'react-toastify';
import "./contact.css";
import data from "../../assets/data.js"
import { Context } from '../../context/langContext'


const ContactForm = () => {
  const [state, handleSubmit] = useForm("xqkodkww");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const {lang} = useContext(Context)

  if (state.succeeded) {  
    toast.success(data[lang].Contact.Toast, {
        position: 'top-center',
        autoClose: 15000,
        theme: "light",
      }); }

 

  const isFormValid = () => {
    return name && email && message;
  };

  return (
    <div className="contact">
        <div className="intro">
        <hr className="hr" />
        <h1>Contact</h1>
      </div>
      <div className="container-form">
        <form onSubmit={handleSubmit}>
          <div className="form-field">
            <label htmlFor="name">{data[lang].Contact.Nom}</label>
            <input
              id="name"
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
        
          </div>
          <div className="form-field">
            <label htmlFor="email">{data[lang].Contact.Email}</label>
            <input
              id="email"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          
          </div>
          <div className="form-field">
            <label htmlFor="phone">{data[lang].Contact.Tel}</label>
            <input id="phone" type="tel" name="phone" />
          </div>
          <div className="form-field">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            
          </div>
          <button type="submit" disabled={!isFormValid()}>
            Submit
          </button>
        </form>
        <ToastContainer limit={1}/>
      </div>
    </div>
  );
};

export default ContactForm;
