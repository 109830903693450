import React, { useState, useContext } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import Twice from "../../images/twice.png"
import Snake from "../../images/snake.jpg"
import Reve from "../../images/reve.jpg"
import Travel from "../../images/travel.jpg"
import Marvel from "../../images/marvel.jpg"
import Article from "../../images/article.jpg"
import Burger from "../../images/burger.jpg"
import JDR from "../../images/jdr.jpg"
import data from "../../assets/data.js";
import { Context } from "../../context/langContext";

import "./portfolio.css";

const Portfolio = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");

  const { lang } = useContext(Context);
  const projects = [
    {
      title: "JDR Titan",
      description: "React + Firebase",
      url: "https://titan.holysheet.fr",
      image: JDR,
      category: "⚛️ React",
    },
    {
      title: "Marvel Quiz",
      description: "React + Firebase",
      url: "https://marvel-quiz-1006e.firebaseapp.com/",
      image: Marvel,
      category: "⚛️ React",
    },
    {
      title: "🍔 Burger Code 🍔",
      description: "HTML/CSS",
      url: "https://burgercode.nathandieu.com",
      image: Burger,
      category: "👨‍💻 HTML/CSS/Javascript",
    },
    {
      title: "Article",
      description: "React + Redux",
      url: "https://nathantenpouin.github.io/blog-react/",
      image: Article,
      category: "⚛️ React",
    },
    {
      title: "🐍 Snake 🐍",
      description: "Snake Javascript",
      url: "https://snake.nathandieu.com",
      image: Snake,
      category: "🎱 Game",
    },  
    {
      title: data[lang].Portfolio.Reve,
      description: "API Google Maps",
      url: "https://reve.nathandieu.com/dist/#",
      image: Reve,
      category: "👨‍💻 HTML/CSS/Javascript",
    },
    {
      title: data[lang].Portfolio.Twice,
      description: "jQuery but no responsive ",
      url: "https://twice.nathandieu.com",
      image: Twice,
      category: "👴 Old Project",
    },
   
  
    {
      title: "Travel Agency",
      description: "HTML/CSS but no responsive",
      url: "https://voyage.nathandieu.com",
      image: Travel,
      category: "👴 Old Project",
    },
    
   
  ];

  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const uniqueCategories = [
    ...new Set(projects.map((project) => project.category)),
  ];

  const renderCategorySelector = () => {
    return (
      <select className="category-selector" value={selectedCategory} onChange={handleCategoryChange}>
        <option value="">🔠 Toutes les catégories</option>
        {uniqueCategories.map((category, index) => (
          <option key={index} value={category}>
            {category}
          </option>
        ))}
      </select>
    );
  };

  const filteredProjects = selectedCategory
    ? projects.filter((project) => project.category === selectedCategory)
    : projects;

  const renderProjects = () => {
    return filteredProjects.map((project, index) => (
      <div
        key={index}
        className="project-card"
        onClick={() => handleProjectClick(project)}
      >
        <h3 className="project-card-title">{project.title}</h3>
        <p className="project-card-description">{project.description}</p>
        <img src={project.image} alt={`image ${project.title}`} className="project-card-image" />
      </div>
    ));
  };

  const renderPreview = () => {
    if (!selectedProject) return null;

    return (
      <div
        className="preview-container"
        onClick={() => setSelectedProject(null)}
      >
        <iframe
          className="preview-frame"
          src={selectedProject.url}
          title={selectedProject.title}
        />

        <IoMdCloseCircle
          size="2em"
          color="white"
          className="close-preview"
          onClick={() => setSelectedProject(null)}
        />
      </div>
    );
  };

  return (
    <div className="portfolio">
      <div className="intro">
        <hr className="hr" />
        <h1>Portfolio</h1>
      </div>

      <div className="portfolio-selector">{renderCategorySelector()}</div>
      <div className="portfolio-container">
        {renderProjects()}
        {renderPreview()}
      </div>
    </div>
  );
};

export default Portfolio;
