import React, { useState } from "react";
import "./project.css";
import Select from "react-select";
import BMICalculator from "./BMICalculator";
import Slider from "./Slider";
import RandomCat from "./Chat";
import MultiForm from "./MultiForm/Multiform";
import Chrono from "./Chrono/Chrono";
import InfiniteScroll from "./InfiniteScroll/InfiniteScroll";


const options = [
  { value: <BMICalculator />, label: "➕ Calculator from BMI " },
  { value: <Slider />, label: "🖼️ Slider" },
  { value: <RandomCat />, label: "🐈‍⬛ Random Cat" },
  { value: <MultiForm />, label: "📝 MultiForm" },
  { value: <Chrono />, label: "⏱️ Chrono" },
  { value: <InfiniteScroll />, label: "♾️ Infinite Scroll" },


];

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "30%", 
    minWidth: "200px",
    maxWidth: "500px",
    margin: "auto", 
    marginTop: "50px", 
    marginBottom: "50px", 
    zIndex: 9999,
  }),
};

const Project = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  return (
    <div className="project">
      <div className="intro">
        <hr className="hr" />
        <h1>Project</h1>
      </div>
      <Select
        options={options}
        styles={customStyles}
        value={selectedOption}
        onChange={handleSelect}
       
      />
      {selectedOption && <> {selectedOption.value}</>}

    </div>
    
  );
};

export default Project;
