import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import './navbar.css';
import { AiOutlineMenu } from 'react-icons/ai';
import ToggleLangs from './ToggleLangs';
import data from "../../assets/data.js"
import { Context } from '../../context/langContext'

const NavBar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [largeur, setLargeur] = useState(window.innerWidth);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  const changeWidth = () => {
    setLargeur(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', changeWidth);

    return () => {
      window.removeEventListener('resize', changeWidth);
    };
  }, []);

  const {lang} = useContext(Context)

  return (
    <nav className='main-navbar'>
      {(toggleMenu || largeur > 930) && (
        <div className='liste'>
          <NavLink
            to='/'
            exact
            onClick={toggleNav}
            className={({ isActive }) =>
              isActive ? 'activeLink items' : 'inactiveLink items'
            }
          >
            {data[lang].NavBar.Moi}
          </NavLink>
          <NavLink
            to='/competences'
            exact
            onClick={toggleNav}
            className={({ isActive }) =>
              isActive ? 'activeLink items' : 'inactiveLink items'
            }
          >
            {data[lang].NavBar.Competences}
          </NavLink>
          <NavLink
            to='/experience'
            exact
            onClick={toggleNav}
            className={({ isActive }) =>
              isActive ? 'activeLink items' : 'inactiveLink items'
            }
          >
            {data[lang].NavBar.Experience}
          </NavLink>
          <NavLink
            to='/education'
            exact
            onClick={toggleNav}
            className={({ isActive }) =>
              isActive ? 'activeLink items' : 'inactiveLink items'
            }
          >
            {data[lang].NavBar.Education}
          </NavLink>
          <NavLink
            to='/project'
            exact
            onClick={toggleNav}
            className={({ isActive }) =>
              isActive ? 'activeLink items' : 'inactiveLink items'
            }
          >
            Project
          </NavLink>
          <NavLink
            to='/portfolio'
            exact
            onClick={toggleNav}
            className={({ isActive }) =>
              isActive ? 'activeLink items' : 'inactiveLink items'
            }
          >
            Portfolio
          </NavLink>
          <NavLink
            to='/contact'
            exact
            onClick={toggleNav}
            className={({ isActive }) =>
              isActive ? 'activeLink items' : 'inactiveLink items'
            }
          >
            Contact
          </NavLink>
           {(toggleMenu || largeur > 930) ? <ToggleLangs /> : ""  } 
        </div>
      )} 
           {largeur > 930  ?  "" :<ToggleLangs />   } 
      <AiOutlineMenu onClick={toggleNav} className='btn-nav' size='2em' />
      
    </nav>
  );
};

export default NavBar;