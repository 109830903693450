import React, { useEffect, useState } from "react";
import axios from "axios";

import './RandomCat.css';

const RandomCat = () => {
  const [catImageUrl, setCatImageUrl] = useState(null);

  useEffect(() => {
    fetchCatImage();
  }, []);

  const fetchCatImage = async () => {
    try {
      const response = await axios.get('https://api.thecatapi.com/v1/images/search');
      setCatImageUrl(response.data[0].url);
    } catch (error) {
      console.error('Erreur lors de la récupération de l\'image de chat:', error);
    }
  };

  const handleRefreshClick = () => {
    fetchCatImage();
  };

  return (
    <div className="cat-container">
      {catImageUrl && 
        <img className="cat-image" src={catImageUrl} alt="Un chat aléatoire" />
       
      }
      <button className="refresh-button" onClick={handleRefreshClick}>
        <span>Refresh</span>
      </button>
    </div>
  );
};

export default RandomCat;
