import React, { useState, useContext } from "react";
import "./BMI.css";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import data from "../../../assets/data.js"
import { Context } from '../../../context/langContext'





function BMICalculator() {
          const {lang} = useContext(Context)
  const BMIData = [
    { name: data[lang].Project.Maigreur , color: "midnightblue", range: [0, 18.5] },
    { name: data[lang].Project.BS, color: "green", range: [18.5, 25] },
    { name: data[lang].Project.Surpoids, color: "lightcoral", range: [25, 30] },
    { name: data[lang].Project.OM, color: "orange", range: [30, 35] },
    { name: data[lang].Project.OS, color: "crimson", range: [35, 40] },
    { name: data[lang].Project.OMM, color: "purple", range: 40 },
  ];
  
  

  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [displayBMI, setDisplayBMI] = useState("");
  const [displayBMIStyle, setDisplayBMIStyle] = useState({});
  const [result, setResult] = useState("");

  const calculateBMI = () => {
    if (!height || !weight || height <= 0 || weight <= 0) {
      handleError();
      return;
    }

    const BMI = (weight / Math.pow(height / 100, 2)).toFixed(1);
    showResult(BMI);
  };

  const handleError = () => {
    setDisplayBMI("Wops");
    setResult(data[lang].Project.Remplir);
  };

  const showResult = (BMI) => {
    const rank = BMIData.find((data) => {
      if (BMI >= data.range[0] && BMI < data.range[1]) return data;
      else if (typeof data.range === "number" && BMI >= data.range) return data;
    });

    setDisplayBMI(BMI);
    setDisplayBMIStyle({ color: rank.color });
    setResult(rank.name);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    calculateBMI();
  };



  return (
    <div className="container-IMC">
      <h1>
        {data[lang].Project.Calcul}{" "}
        <span
          data-tooltip-id="my-tooltip"
          data-tooltip-content={data[lang].Project.Tool}
          data-tooltip-place="top"
        >
          {data[lang].Project.Span}
        </span>{" "}
        <Tooltip id="my-tooltip" style={{ fontSize: "9px" }} />
      </h1>
      <form onSubmit={handleFormSubmit}>
        <div className="inputs-container">
          <div className="input-group">
            <label htmlFor="height">{data[lang].Project.Taille}:</label>
            <input
              type="number"
              id="height"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="weight">{data[lang].Project.Poids}:</label>
            <input
              type="number"
              id="weight"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
            />
          </div>
        </div>
        <button type="submit">{data[lang].Project.Calculer}</button>
        <div className="info-IMC">
          <p className="bmi-value" style={displayBMIStyle}>
            {displayBMI}
          </p>
          <p className="result">{result}</p>
        </div>
      </form>
    </div>
  );
}

export default BMICalculator;
