import React, { useEffect, useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './NotFound.css';
import data from "../../assets/data.js"
import { Context } from '../../context/langContext'



const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const redirectToHomePage = () => {
      navigate('/');
    };
    // Utiliser un toastId pour limiter à un seul toast à la fois
    const toastId = "redirection-toast";

    if (!toast.isActive(toastId)) {
        
      toast.error(`${data[lang].Error.toast}`, {
        position: 'top-center',
        autoClose: 10000,
        theme: "dark",
        toastId,
      });
    }
    const timer = setTimeout(redirectToHomePage, 10000);
    return () => clearTimeout(timer);
  }, [navigate]);

  const {lang} = useContext(Context)


  return (
    <div className="notFoundContainer">
      <h1 className="notFoundHeader">Oops! 🤭</h1>
      <p className="notFoundText" >
        {data[lang].Error.texte} 🚀
      </p>
      <img
         src="https://media.tenor.com/uv2WQ3HylPkAAAAC/portals-portal.gif   "
         alt="Space Core criant 'SPACE'"
        className="notFoundImage"
      />
      <Outlet />
      <ToastContainer />
    </div>
  );
};

export default NotFound;