import React, {useContext} from "react";
import "./education.css"
import data from "../../assets/data.js"
import { Context } from '../../context/langContext'
import {FcDiploma1} from "react-icons/fc"

const Education = () => {
  
    const {lang} = useContext(Context)

    return (
        <div className="education">
             <div className="intro">
        <hr className="hr" />
        <h1>            {data[lang].NavBar.Education}
</h1>
      </div>
          <div className="diplomes-container">
           {data[lang].Diplome.map((e) => (
                <div key={e.id} className="education-block">
                    <h5><FcDiploma1 size="3em" />{e.annee}</h5>
                    
                    <h3>{e.nom}</h3>
                    <h2>{e.titre}</h2>
                    <hr className="diplomes-hr"></hr>
                    <h4>{e.description1}</h4>
                    <h4>{e.description2}</h4>
                </div>
            ))} 
        </div>
        </div>
    );
};


export default Education;
