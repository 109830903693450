import { Routes, Route } from "react-router-dom";
import Competences from "./Components/Competence";
import ContactForm from "./Components/Contact";
import Education from "./Components/Education";
import NotFound from "./Components/Error 404";
import Experience from "./Components/Experience";
import Footer from "./Components/Footer";
import Moi from "./Components/Moi";
import NavBar from "./Components/Navbar";
import Portfolio from "./Components/Portfolio";
import Project from "./Components/Project";
import ContextProvider from "./context/langContext";

function App() {
  return (
    <>
      <ContextProvider>
        <NavBar />
        <Routes>
          <Route path="/" element={<Moi />} />
          <Route path="/competences" element={<Competences />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="/education" element={<Education />} />
          <Route path="/project" element={<Project />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<ContactForm />} />
           
 
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </ContextProvider>
    </>
  );
}

export default App;
