import React, { useContext } from "react";
import Me from "../../images/icon.png";
import CV from "../../ressources/CV.pdf";
import "./me.css";
import { DiReact } from "react-icons/di";
import data from "../../assets/data.js";
import { Context } from "../../context/langContext";

const Moi = () => {
  const { lang } = useContext(Context);

  return (
    <div className="Me">
      <div className="intro">
        <hr className="hr" />
        <h1>{data[lang].NavBar.Moi}</h1>
      </div>
    <div className="cv-container">
      <div className="cv-profile">
        <img src={Me}alt="Photo de profil" />
        <h1 className="cv-name">{data[lang].Moi.Presentation}<span>({data[lang].Moi.Joke} <DiReact  size="1.5em"/>)</span></h1>
        <h2 className="cv-title">{data[lang].Moi.DV}</h2>
        <div className="cv-about">
      <h3>{data[lang].Moi.Propos}</h3>
      <p>{data[lang].Moi.Description}</p>
    </div>
        <a className="cv-download-link" href={CV} download="CV_Dieu_Nathan">
        {data[lang].Moi.Telecharger}
        </a>
      </div>
    </div>
</div>
 
  );
};

export default Moi;
