import React from 'react'
import './Card.css'

export default function CardEnd() {
    return (
        <div className="card">
            <h1>Bravissimo !</h1>
            <p>On peut maintenant te proposer des plats en fonction de tes goûts.</p>
        </div>
    )
}
