import { v4 as uuidv4 } from "uuid";

const dataSlider = [
  {
    id: uuidv4(),
    title: "plage",
    subTitle: "Lorem"
  },
  {
    id: uuidv4(),
    title: "bateau",
    subTitle: "Lorem"
  },
  {
    id: uuidv4(),
    title: "ordinateur",
    subTitle: "Lorem"
  },
  {
    id: uuidv4(),
    title: "vélo dans champs",
    subTitle: "Lorem"
  },
  {
    id: uuidv4(),
    title: "fleur",
    subTitle: "Lorem"
  },
];

export default dataSlider;
